import React from "react";

function agb() {
  return (
    <div className="container-fluid p-4 m-lg-4">
      <section className="card mb-4">
        <div className="card-body text-muted row">
          <div className="col-lg-6">
            <ul className="list-unstyled">
              <li>Vanessa Heiderer</li>
              <li>Büro OÖ: Frauschereck 23, 5242 St. Johann</li>
              <li>Büro Wien: Daringergasse 3/9, 1190</li>
              <li>Wien Tel: +43 (0)676 5011868</li>
              <li>office@creperie-mobile.at</li>
            </ul>
          </div>
          <div className="col-lg-6">
            <ul className="list-unstyled">
              <li>UID: ATU 62808402</li>
              <li>Str. Nr. 139/3433</li>
              <li>gew. Reg. Nr. 404/11182</li>
              <li>Kammer WKO OÖ, Fachgruppe Gastronomie</li>
            </ul>
          </div>
        </div>
      </section>
      <h3 className=" text-center pb-4">Datenschutzerklärung für die Website</h3>
      <section className="card mb-4">
        <div className="card-body text-muted">
          Wir, creperie-mobile.at, betreiben diese Webseite als Informationsplattform sowie als
          Kontaktaufnahmemöglichkeit zu verschiedenen Themen wie Anfragen zu unseren Leistungen, Bewerbungen oder ganz
          allgemeiner Natur und freuen uns über Ihren Besuch. Wir wollen unsere Dienste für Sie effizient und
          benutzerfreundlich gestalten sowie kontinuierlich ver­bessern. Gleichzeitig möchten wir, dass Sie sich als
          Nutzer bestmöglich aufgeklärt und sicher fühlen. Daher verpflichten wir uns Ihre Privatsphäre, Ihre
          Persönlichkeits- und Datenschutzrechte zu respektieren, Ihre Daten vertraulich zu behandeln sowie keine Ihrer
          Daten verdeckt zu erfassen oder auszuwerten. Diese Datenschutz-Erklärung ist aktuell gültig und datiert vom
          Mai 2018.
        </div>
      </section>
      <section className="card mb-4">
        <div className="card-header">1. Unsere Maßnahmen</div>
        <div className="card-body text-muted">
          Die Einhaltung dieser Datenschutz-Erklärung wird laufend kontrolliert. Wir sorgen für die notwendige
          Transparenz der Datenverarbeitung. Wir sichern den Besuch unserer Webseite nach dem aktuellen Stand der
          Technik. Wir erheben, verarbeiten und nutzen Ihre Daten nur, wenn es gesetzliche Vorgaben gibt, ein
          berech­tigtes Interesse besteht oder Sie dazu Ihr Einverständnis erklärt haben. Wir geben Ihre
          personenbezogenen Daten nicht an Dritte außerhalb des Konzerns weiter, es sei denn, dass wir hierzu gesetzlich
          verpflichtet sind oder Sie uns vorher Ihre Zustimmung erteilt haben. Insbesondere werden personenbezogene
          Daten, die von Ihnen zu uns sowie gegebenenfalls zurück übertragen werden, ausnahmslos gegen die Kenntnisnahme
          durch Unbefugte hinreichend ver­schlüsselt, sofern Sie hierfür die auf unseren Internetseiten angebotenen
          Kontaktformulare nutzen. Unsere Webseite erstellt keine personenbezogenen Nutzerprofile.
        </div>
      </section>
      <section className="card mb-4">
        <div className="card-header">
          2. Erklärung zur Informationspflicht Erhebung und Verarbeitung von personenbezogenen Daten
        </div>
        <div className="card-body text-muted">
          Bei Besuch unserer Webseiten speichern wir je nach Nutzung unserer Dienste Ihre personenbezogenen Daten.
          Einerseits übermitteln Sie uns automatisch bestimmte Daten, damit können wir uns beispielsweise über die
          Besucherzahlen informieren, andererseits stellen Sie uns eventuell auch bewusst Daten zur Verfügung um mit uns
          Kontakt aufzunehmen (Korrespondenz, Bewerbungen, Sponsoring). Wir verwenden Ihre personenbezogenen Daten
          ausschließlich unter Einhaltung der nationalen und euro­päischen Datenschutzvorschriften und nur in jenem
          Umfang und für jenen Zweck, für den Sie uns die Daten zur Verfügung gestellt haben, und für keinen anderen
          Zweck.
        </div>
      </section>
      <section className="card mb-4">
        <div className="card-header">Löschung</div>
        <div className="card-body text-muted">
          Die Löschung der gespeicherten personenbezogenen Daten erfolgt, wenn der Nutzer der Webseite die Einwilligung
          zur Spei­cherung widerruft, wenn ihre Kenntnis zur Erfüllung des mit der Speicherung verfolgten Zwecks nicht
          mehr erforderlich ist oder wenn ihre Speicherung aus sonstigen gesetzlichen Gründen unzulässig ist. Daten für
          Abrechnungszwecke und buchhalterische Zwecke werden von einem Löschungsverlangen nicht berührt.
        </div>
      </section>
      <section className="card mb-4">
        <div className="card-header">3. IP-Adressen Serverlogs</div>
        <div className="card-body text-muted">
          Bei jedem Besuch unserer Webseite wird Ihre IP-Adresse gemeinsam mit anderen Daten an den Webserver
          übertragen. Das ist aus technischen Gründen notwendig, um Ihnen die Antwort des Servers übermitteln zu können.
          Sie erhalten diese IP-Adresse von Ihrem Internet Service Provider, sobald Sie sich mit dem Internet verbinden.
          Ihr Provider kann anschließend über einen gewissen Zeitraum nachvollziehen, welche IP-Adresse Sie zu einem
          bestimmten Zeitpunkt verwendet haben. Über eine Anfrage an den Provider kann daher zumindest theoretisch Ihre
          Identität ermittelt werden. Zur Prüfung der Systemsicherheit, zur Abwehr und Nachverfolgbarkeit von
          Hacking-Angriffen, zur techni­schen Systemadministration sowie zur Optimierung der Bedienbarkeit der Website
          werden die Serverlogs (IP-Adresse und weitere Daten wie Datum, Uhrzeit der Anfrage, Name und URL der
          angefragten Datei, übertragenen Datenmenge, Meldung, ob Anfrage erfolgreich war, Erkennungsdaten des
          verwendeten Browsers und des Betriebssystems, sowie im Falle eines Zugriffs über einen Links, die Webseite,
          von der der Zugriff erfolgte) protokolliert. Eine Einsicht in die Serverlogs erfolgt nur im Anlass- oder
          Verdachtsfall. Eine Weitergabe der Daten erfolgt nur im Falle eines Hackerangriffes an IT-Forensiker und/oder
          die Strafverfol­gungsbehörden. Eine darüber hinausgehende Weitergabe an Dritte erfolgt nicht. Die Daten werden
          nicht dauerhaft gespeichert sondern nach einer Dauer von 6 Monate gelöscht.
        </div>
      </section>
      <section className="card mb-4">
        <div className="card-header">4. Cookies</div>
        <div className="card-body text-muted">
          Wir benutzen Cookies, um die Nutzerfreundlichkeit zu optimieren und um zu verstehen, wie unsere Web­seite
          benutzt wird.
        </div>
        <div className="card-body text-muted">
          Was sind Cookies? Cookies sind kleine Textdateien, die an Ihren Webbrowser geschickt und lokal auf Ihrem PC
          gespeichert werden. Zum einen steuern diese Dateien die Darstellung und Bedienung der Seite. Zum anderen
          werden sie eingesetzt, um nützliche Informationen über die Verwendung unserer Seiten zu gewinnen. Einige
          Cookies werden nur temporär gespeichert und beim Schließen des Browsers gelöscht. Andere Cookies (sog.
          „persistente Cookies“) werden für einen längeren Zeitraum oder dauerhaft gespeichert bzw. bis zum Erreichen
          eines Ablaufdatums oder bis zur manuellen Löschung aus Ihrem Browser-Cache. Cookies, die creperie-mobile.at
          setzt, haben eine Gültigkeitsdauer von bis zu zwei Jahren, sofern nicht in den speziellen
          Cookie-Beschreibungen etwas anderes angegeben ist. Nach Ablauf der zwei Jahre werden die Cookies automatisch
          gelöscht. Unabhängig davon können Sie Cookies jederzeit manuell löschen.
        </div>
        <div className="card-body text-muted">
          Wie verwalten oder verhindern Sie die Speicherung von Cookies? Sie können die Speicherung der Cookies durch
          eine entsprechende Einstellung Ihrer Browser-Software regeln oder auch gänzlich verhindern. Abhängig vom
          Hersteller (Browsermenü meist Menüpunkt: „Sicherheit/Datenschutz-Erklärung“) können Sie unterschiedliche
          Sicherheits-Einstellungen benützen (Warnung vor Cookie-Speicherung, Einschränkungen oder gänzliche
          Unterbindung der Annahme und Ablage von Cookies). Zu vielen Browsern gibt es außerdem Erweiterungen (Add-ons),
          mit denen Sie einzelne Cookies gezielt ablehnen können. Wir weisen Sie jedoch darauf hin, dass Sie
          gegebenenfalls nicht sämtliche Funktionen dieser Webseite vollumfänglich werden nutzen können.
        </div>
      </section>
      <section className="card mb-4">
        <div className="card-header">5. Datenschutz-Hinweis zu Google Analytics</div>
        <div className="card-body text-muted">
          Diese Website benutzt Google Analytics, einen Webanalysedienst der Google Inc. („Google“). Google Analytics
          verwendet sog. „Cookies“, Textdateien, die auf Ihrem Computer gespeichert werden und die eine Analyse der
          Benutzung der Website durch Sie ermöglichen (siehe Cookies). Die durch das Cookie erzeugten Informationen über
          Ihre Benutzung dieser Website (einschließlich ihrer IP-Adresse) werden in der Regel an einen Server von Google
          in den USA übertragen und dort gespeichert. Aufgrund der von uns aktivierten IP-Anonymisierung auf dieser
          Website wird Ihre IP-Adresse von Google zuvor gekürzt (sog. IP-Masking, Funktion „anonymizeIp“). Nur in
          Ausnahmefällen wird die volle IP-Adresse an einen Server von Google in den USA übertragen und dort gekürzt.
          Google wird diese Informationen benutzen, um Ihre Nutzung der Website auszuwerten, um Reports über die
          Websiteaktivitäten für uns als Websitebetreiber zusammenzustellen und um weitere mit der Websitenutzung und
          der Internetnutzung verbundene Dienstleistungen zu erbringen. Auch wird Google diese Informationen
          gegebenenfalls an Dritte übertragen, sofern dies gesetzlich vorgeschrieben oder soweit Dritte diese Daten im
          Auftrag von Google verarbeiten. Sie können entweder durch Ihre Browser-Einstellung eine Speicherung von
          Cookies verhindern oder darüber hinaus die Erfassung der durch das Cookie erzeugten und auf Ihre Nutzung der
          Website be­zogenen Daten (inkl. Ihrer IP-Adresse) an Google sowie die Verarbeitung dieser Daten durch Google
          verhindern, indem Sie das unter dem folgenden Link verfügbare Browser-Plugin herunterladen und installieren.
          Nähere Informationen zu Nutzungsbedingungen und Datenschutz finden Sie hier bzw. hier.
        </div>
      </section>
      <section className="card mb-4">
        <div className="card-header">6. Verwendung von Google Maps</div>
        <div className="card-body text-muted">
          Diese Webseite verwendet Google Maps API, um geographische Informationen visuell darzustellen. Bei der Nutzung
          von Google Maps werden von Google auch Daten über die Nutzung der Kartenfunktionen durch Besucher erhoben,
          verarbeitet und genutzt. Nähere Informationen über die Datenverarbeitung durch Google können Sie den
          Google-Datenschutzhinweisen entnehmen. Dort können Sie im Datenschutzcenter auch Ihre persönlichen
          Datenschutz-Einstellungen verändern. Ausführliche Anleitungen zur Verwaltung der eigenen Daten im Zusammenhang
          mit Google-Produkten finden Sie hier.
        </div>
      </section>
      <section className="card mb-4">
        <div className="card-header">7. Eingebettete YouTube-Videos</div>
        <div className="card-body text-muted">
          Auf unserer Webseite betten wir Youtube-Videos ein. Betreiber der entsprechenden Plugins ist die YouTube, LLC,
          901 Cherry Ave., San Bruno, CA 94066, USA. Wenn Sie eine Seite mit dem YouTube-Plugin besuchen, wird eine
          Verbindung zu Servern von Youtube hergestellt. Dabei wird Youtube mitgeteilt, welche Seiten Sie besuchen. Wenn
          Sie in Ihrem Youtube-Account eingeloggt sind, kann Youtube Ihr Surfverhalten Ihnen persönlich zuzuordnen. Dies
          verhindern Sie, indem Sie sich vorher aus Ihrem Youtube-Account ausloggen. Wird ein Youtube-Video gestartet,
          setzt der Anbieter Cookies ein, die Hinweise über das Nutzerverhalten sammeln. Wer das Speichern von Cookies
          für das Google-Ad-Programm deaktiviert hat, wird auch beim Anschauen von Youtube-Videos mit keinen solchen
          Cookies rechnen müssen. Youtube legt aber auch in anderen Cookies nicht-personenbezogene Nutzungsinformationen
          ab. Möchten Sie dies verhindern, so müssen Sie das Speichern von Cookies im Browser blockieren. Weitere
          Informationen zum Datenschutz bei „Youtube“ finden Sie in der Datenschutzerklärung des Anbieters unter:
          https://www.google.de/intl/de/policies/privacy/
        </div>
      </section>
      <section className="card mb-4">
        <div className="card-header">8. Kontaktaufnahme per E-Mail</div>
        <div className="card-body text-muted">
          Treten Sie per E-Mail mit uns in Kontakt, werden die von Ihnen gemachten (Mindest-) Angaben zum Zwecke der
          Bearbeitung der Anfrage sowie für mögliche Anschlussfragen gespeichert und gegebenenfalls an mit
          creperie-mobile.at konzernverbundene Unternehmen weitergeleitet.
        </div>
      </section>
      <section className="card mb-4">
        <div className="card-header">9. Dienstleister – Auftragsverarbeiter – Kooperationspartner</div>
        <div className="card-body text-muted">
          An der Erbringung unserer Dienste und Services, wie beispielsweise zur Verbesserung, Wartung und Pflege
          unserer Webseite, sind zum Teil Kooperationspartnern beteiligt. Wir arbeiten nur mit Vertrags­partnern
          zusammen, die hinreichende Garantien dafür bieten, dass Ihre Daten bei diesen in sicheren Händen sind.
        </div>
      </section>
      <section className="card mb-4">
        <div className="card-header">10. Kontakt (Auskunfts- und Korrekturrechte)</div>
        <div className="card-body text-muted">
          Wir möchten Sie weiters darüber informieren, dass Sie jederzeit das Recht haben, Auskunft darüber zu
          verlangen, welche Daten von Ihnen bei uns verarbeitet werden (siehe im Detail Art 15 DSGVO); das Recht haben,
          Ihre Daten berichtigen oder löschen zu lassen (siehe im Detail Art 16 DSGVO); das Recht haben, die
          Verarbeitung Ihrer Daten einzuschränken (siehe im Detail Art 18 DSGVO); das Recht haben, der Datenverarbeitung
          zu widersprechen (siehe im Detail Art 21 DSGVO); das Recht auf Datenübertragbarkeit geltend machen können
          (siehe im Detail Art 20 DSGVO). Sollte es wider Erwarten zu einer Verletzung Ihrer Rechte in Bezug auf Daten
          kommen, haben Sie das Recht, eine Beschwerde bei der Datenschutzbehörde, (für Österreich: Österreichische
          Datenschutz­behörde, Wickenburggasse 8, 1080 Wien, E?Mail: dsb@dsb.gv.at) zu erheben.
        </div>
      </section>
      <section className="card mb-4">
        <div className="card-header">Widerruf Ihres Einverständnisses</div>
        <div className="card-body text-muted">
          Bezüglich Ihrer personenbezogenen Daten, die wir auf Grund Ihrer Einwilligung verwenden, haben Sie das Recht,
          Ihre Einwilligung zur Datenverwendung jederzeit unter unten angegebenen Kontaktdaten zu widerrufen, ohne dass
          die Rechtmäßigkeit der auf Grund der Einwilligung bis zum Widerruf erfolgten Verarbeitung berührt wird.
        </div>
      </section>
      <section className="card mb-4">
        <div className="card-header">Kontakt</div>
        <div className="card-body text-muted">
          Bitte wenden Sie sich unter folgender Adresse an uns, wenn Sie Fragen hinsichtlich der Verarbeitung,
          Übermittlung, Nutzung oder zu Schutz und Sicherheit Ihrer Daten haben, Ihre Einwilligung widerrufen möchten,
          Ihre Rechte und Ansprüche im Zusammenhang mit Datenschutz geltend machen wollen (z.B. ein Aus­kunftsersuchen),
          Anregungen oder eventuelle Datenschutzbeschwerden haben.
        </div>
      </section>
    </div>
  );
}

export default agb;
